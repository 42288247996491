<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:px-20 space-y-7 w-full">
        <h1 class="lg:text-3xl text-2xl font-semibold mb-6">Tạo hỗ trợ mới</h1>
        <div class="breadcrumb-area py-0 flex items-center justify-content-between">
          <div class="breadcrumb">
            <ul class="m-0">
              <li>
                <router-link :to="{name: 'TicketList'}">
                  Hòm thư hỗ trợ
                </router-link>
              </li>
              <li class="active">
                Tạo hỗ trợ mới
              </li>
            </ul>
          </div>
        </div>
        <div v-if="message">
          <alert :message="message" :status="status"/>
        </div>
        <form v-on:submit.prevent="createTicket" class="grid grid-cols-1 gap-3">
          <div>
            <label for="title" class="font-semibold">Tiêu đề (*)</label>
            <input v-model="ticket.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                   required>
          </div>
          <div>
            <label for="title" class="font-semibold">Chuyên mục (*)</label>
            <select v-model="ticket.category">
              <template v-for="category in categories" :key="'category' + category.key">
                <option :value="category.key" v-if="!category.sub">{{ category.label }}</option>
                <optgroup v-if="category.sub" :label="category.label">
                  <option v-for="subCategory in category.sub" :key="'subCategory' + subCategory.key"
                          :value="subCategory.key">{{ subCategory.label }}
                  </option>
                </optgroup>
              </template>
            </select>
          </div>
          <div>
            <label for="content" class="font-semibold">Nội dung (*)</label>
            <editor :full="true" id="content" v-model:content="ticket.content"/>
          </div>
          <div class="border-t flex justify-center border-gray-100 p-6">
            <button :disabled="disabled" type="submit" class="button lg:w-1/3 hover:bg-blue-800">Tạo mới</button>
          </div>
        </form>
      </div>
    </div>

    <alert :message="message"/>
  </div>
</template>

<script>
import Editor from "../Editor";
import ApiService from "../../core/services/api.service";
import {getTicketCategories} from "../../core/services/utils.service";

export default {
  name: "TicketAdd",
  components: {Editor},
  data() {
    return {
      categories: [],
      ticket: {
        title: "",
        content: "",
        category: ""
      },
      disabled: false
    }
  },
  methods: {
    createTicket() {
      this.disabled = true;
      this.message = "";

      let query = `mutation($title: String!, $content: String!, $category: String!) {
        createTicket(input: {
          title: $title,
          category: $category,
          content: $content
        }) {
          id
        }
      }`;

      ApiService.graphql(query, this.ticket)
          .then(({data}) => {
            if (data.data && data.data.createTicket) {
              this.$toast.success("Tạo góp ý thành công");
              this.$router.push({name: 'TicketList'});
            } else {
              this.disabled = false;
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
            this.disabled = false;
          });
    },
    handleTicketCategory() {
      switch (this.ticket.category) {
        case "UNG_HO_CA_NHAN":
          this.ticket.title = "Ủng hộ cho trang nhạc";
          this.ticket.content = "STK: 11022323556011<br>Chủ TK: Nguyễn Trọng Bằng<br>Ngân hàng Techcombank<br><br>Lời nhắn: ";
          break;
        case "TAI_TRO_DOANH_NGHIEP":
          this.ticket.title = "Tài trợ cho trang nhạc";
          this.ticket.content = "STK: 11022323556011<br>Chủ TK: Nguyễn Trọng Bằng<br>Ngân hàng Techcombank<br><br>Lời nhắn: ";
          break;
        case "TAI_TRO_CHINH_PHU":
          this.ticket.title = "Tài trợ cho trang nhạc";
          this.ticket.content = "STK: 11022323556011<br>Chủ TK: Nguyễn Trọng Bằng<br>Ngân hàng Techcombank<br><br>Lời nhắn: ";
          break;
        case "CONG_TAC_TRUYEN_THONG":
          this.ticket.title = "Tham gia ban Truyền thông";
          this.ticket.content = "Lời nhắn: ";
          break;
        case "CONG_TAC_PTST":
          this.ticket.title = "Tham gia ban Phát triển sáng tạo";
          this.ticket.content = "Lời nhắn: ";
          break;
        case "CONG_TAC_CTV":
          this.ticket.title = "Tham gia CTV các chuyên mục";
          this.ticket.content = "Chuyên mục muốn tham gia: <br>Lời nhắn: ";
          break;
      }
    }
  },
  created() {
    this.ticket.category = this.$route.query.category;
  },
  mounted() {
    this.categories = getTicketCategories();
  },
  watch: {
    "ticket.category": {
      handler() {
        this.handleTicketCategory();
      }
    }
  }
}
</script>
